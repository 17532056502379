<template>
    <div>
        <el-upload
            class="upload-demo"
            drag
            action=""
            :http-request="uploadFile"
            :on-remove="handleRemove"
            multiple
            :on-exceed="handleExceed"
            :limit="1"
        >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
                <p>注意一行不能超过1024字节</p>
            </div>
        </el-upload>
        <el-button type="primary" size="default" @click="running"
            >运行</el-button
        >
        <el-button type="primary" size="default" @click="tableToExcel"
            >导出</el-button
        >
    </div>
</template>

<script>
import { getJoke } from "../api/test";
export default {
    data() {
        return {
            input: [],
            subArray: [],
            file: null,
        };
    },
    methods: {
        handleExceed(files) {
            console.log("files", files);
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        uploadFile({ file }) {
            this.file = file;
            console.log("this.file", this.file);
            console.log("this.file.type", this.file.type);
            // if (this.file.type != "text/plain") {
            //     this.$message.error("仅支持上传txt");
            //     return;
            // }
            let reader = new FileReader();
            console.log("reader", reader);
            reader.onload = (evt) => {
                if (evt.target.readyState !== 2) return;
                if (evt.target.error) {
                    console.log("Error while reading file");
                    return;
                }
                console.log("evt.target.result", evt.target.result);
                const chunkContent = evt.target.result || "";
                // let reg = /(\r\n?|\n)/gm;
                let result;
                if (chunkContent !== null) {
                    //读取一行
                    // let cursor = result.index;
                    // this.input = chunkContent.substr(0, cursor);
                    console.log("result", result);
                    // 读取指定行数
                    console.log("chunkContent", chunkContent.split(/\s+/));
                    this.input = chunkContent
                        .split(/\s+/, 10000)
                        .filter(Boolean);
                    // this.input = chunkContent.split(/\s+/, 10).map(Number);
                    console.log("input", this.input);
                    this.subArray = this.cutArray(this.input, 1000);
                    console.log("this.subArray", this.subArray);
                } else {
                    console.log("1111111111111");
                    console.log("chunkContent", chunkContent);
                    this.input = chunkContent.split(/\s+/);
                    console.log("input", this.input);
                }

                this.file = null;
            };

            // reader.readAsText(this.file.slice(0, 100000));
            reader.readAsText(this.file);
        },
        //数组分割
        cutArray(array, subLength) {
            let index = 0;
            let newArr = [];
            while (index < array.length) {
                newArr.push(array.slice(index, (index += subLength)));
            }
            return newArr;
        },
        running() {
            let pms = [];
            for (let index = 0; index < this.subArray.length; index++) {
                pms.push(getJoke(index));
            }
            Promise.all(pms)
                .then((values) => {
                    console.log(values);
                })
                .catch(() => {});
        },
        tableToExcel() {
            //要导出的json数据
            //要导出的json数据
            var jsonData = [];
            let arr = 123456;
            for (let i = 0; i < 10000; i++) {
                jsonData.push(arr + i);
            }

            console.log("jsonData", jsonData);
            //列标题，逗号隔开，每一个逗号就是隔开一个单元格
            let str = "";
            //增加\t为了不让表格显示科学计数法或者其他格式
            for (let i = 0; i < jsonData.length; i++) {
                str += jsonData[i] + "\t" + "\n";
            }
            console.log("str", str);
            // encodeURIComponent解决中文乱码;
            let uri =
                "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
            //通过创建a标签实现
            var link = document.createElement("a");
            link.href = uri;
            //对下载的文件命名
            link.download = "批量转换用户信息数据表.csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },
};
</script>

<style>
</style>