<template>
    <div class="content">
        <div class="warp">
            <div class="operation-header">
                <div class="h-title"></div>
            </div>
            <div class="img_box">
                <template>
                    <div class="poster">
                        <img class="imageUrl" :src="imageUrl" alt="" />
                        <img
                            class="codeImg"
                            v-if="codeImg != ''"
                            :src="codeImgList[imgIndex].img"
                            alt=""
                        />
                    </div>
                    <div class="canvasDiv">
                        <img :src="posterBase64" alt="" class="real_pic" />
                        <div class="tips">移动端长按图片保存</div>
                    </div>
                </template>
                <div class="prev" @click="prev"></div>
                <div class="next" @click="next"></div>
            </div>

            <div class="operation-btns">
                <a href="javascript:;" class="file">
                    <el-upload
                        class="upload-demo"
                        action=""
                        multiple
                        :limit="999999999999"
                        :http-request="uploadFile"
                        :show-file-list="false"
                    >
                        <el-button size="small" class="btn" type="primary"
                            >点击上传</el-button
                        >
                    </el-upload>
                </a>
            </div>

            <div>
                <el-button type="danger" size="medium" round @click="down"
                    >下载</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from "html2canvas";

export default {
    components: {},
    data() {
        return {
            dialogVisible: true,
            imageUrl: require("@/assets/img/share.jpg"),
            codeImg: require("@/assets/img/hat0.png"),
            posterBase64: "", //  转化后的base64编码
            imgIndex: 0,
            codeImgList: [
                {
                    img: require("@/assets/img/hat0.png"),
                },
                {
                    img: require("@/assets/img/hat1.png"),
                },
                {
                    img: require("@/assets/img/hat2.png"),
                },
                {
                    img: require("@/assets/img/hat3.png"),
                },
                {
                    img: require("@/assets/img/hat4.png"),
                },
                {
                    img: require("@/assets/img/hat5.png"),
                },
                {
                    img: require("@/assets/img/hat6.png"),
                },
                {
                    img: require("@/assets/img/hat7.png"),
                },
            ],
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.codeChangePoster();
    },
    methods: {
        down() {
            var $a = document.createElement("a");
            $a.href = this.posterBase64;
            $a.download = "yyds";
            $a.click();
        },
        codeChangePoster() {
            let that = this;
            let targetDom = document.querySelector(".poster");

            var opts = {
                dpi: 192, // 将分辨率提高到特定的dpi,默认值为96
                scale: 2, // 按比例增加分辨率,默认值为1,pc端值为1,手机端值为2
                // width: width,
                // height: height,
                useCORS: true, // 为解决跨域问题
                logging: true, // console中打印日志
            };
            console.log("targetDom", targetDom);
            html2canvas(targetDom, opts).then(function (canvas) {
                let image = canvas.toDataURL("image/jpeg");
                that.posterBase64 = image;
                console.log(image);
            });
        },

        uploadFile({ file }) {
            console.log("file", file);
            this.imageUrl = URL.createObjectURL(file);
            this.getCodeChangePoster();
        },
        getCodeChangePoster() {
            setTimeout(() => {
                this.codeChangePoster();
            }, 100);
        },
        prev() {
            this.getCodeChangePoster();
            if (this.imgIndex <= 0) {
                this.imgIndex = this.codeImgList.length - 1;
            } else {
                this.imgIndex--;
            }
        },
        next() {
            this.getCodeChangePoster();
            if (this.imgIndex >= this.codeImgList.length - 1) {
                this.imgIndex = 0;
            } else {
                this.imgIndex++;
            }
        },
    },
};
</script>
<style  lang="less" >
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.content {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    min-height: 100vh;
    max-width: 500px;
    margin: 0 auto;
    background-image: url("../assets/img/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    .warp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .operation-header {
        margin-top: 60px;
        .h-title {
            margin-top: 20px;
            width: 230px;
            height: 100px;
            background-image: url("../assets/img/logo2.png");
            background-size: cover;
            margin-bottom: 60px;
        }
    }
    .img_box {
        position: relative;
        height: 152px;
        width: 152px;
        .poster {
            position: relative;
            width: 100%;
            height: 100%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            img {
                width: 100%;
                height: 100%;
            }
            .imageUrl,
            .codeImg {
                position: absolute;
                top: 0;
                left: 0;
            }
            .codeImg {
                z-index: 2;
            }
        }
    }
    .file {
        position: relative;
        display: inline-block;
        width: 180px;
        height: 55px;
        background-image: url("../assets/img/btn1.png");
        background-size: cover;
        overflow: hidden;
        text-decoration: none;
        text-indent: 0;
        line-height: 20px;
        cursor: pointer;
        cursor: pointer;
        margin: 50px 0;
        .btn {
            position: absolute;
            font-size: 100px;
            right: 0;
            top: 0;
            opacity: 0;
            width: 180px;
            height: 55px;
        }
    }
}

.shareImg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    img {
        height: 100%;
        width: 100%;
    }
}
.canvasDiv {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    .real_pic {
        height: 100%;
        width: 100%;
    }
}
.prev,
.next {
    width: 36px;
    height: 36px;
    background-image: url("../assets/img/btn.png");
    background-size: 100% 100%;
    cursor: pointer;
}
.prev {
    position: absolute;
    top: 50%;
    left: -56px;
    transform: translateY(-50%) rotate(180deg);
}
.next {
    position: absolute;
    top: 50%;
    right: -56px;
    transform: translateY(-50%);
}
.tips {
    margin-bottom: 30px;
}
</style>
