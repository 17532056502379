<template>
    <div>
        <el-button type="primary" @click="$router.push('/datePicker')"
            >日期时间组件</el-button
        >
        <el-button type="primary" @click="$router.push('/uploadFile')"
            >上传组件</el-button
        >
        <el-button type="primary" @click="$router.push('/canvas')"
            >国庆头像</el-button
        >
        <div class="box"></div>
    </div>
</template>

<script>
import $ from "jquery";
export default {
    data() {
        return {};
    },
    mounted() {
        $(".box").css({
            background: "#f00",
            width: "400px",
            height: "200px",
        });
    },
    methods: {
        test() {
            let arr = [
                {
                    src_data: 123456789,
                    dst_data: 987654321,
                },
                {
                    src_data: 123456789,
                    dst_data: 987654321,
                },
                {
                    src_data: 123456789,
                    dst_data: 987654321,
                },
                {
                    src_data: 123456789,
                    dst_data: 987654321,
                },
                {
                    src_data: 123456789,
                    dst_data: 987654321,
                },
                {
                    src_data: 123456789,
                    dst_data: 987654321,
                },
                {
                    src_data: 123456789,
                    dst_data: 987654321,
                },
                {
                    src_data: 123456789,
                    dst_data: 987654321,
                },
            ];

            let str = "";
            arr.slice(0, 3).forEach((item) => {
                if (item.dst_data) {
                    str += "<p>" + item.dst_data + "</p>" + "\n";
                } else {
                    str = "转换失败，请确认转换类型和提交数据是否正确";
                }
            });
            console.log("str", str);
        },
    },
};
</script>

<style>
</style>