import Vue from 'vue'
import Router from 'vue-router'
import index from '../pages/index.vue'
import uploadFile from '../pages/uploadFile.vue'
import datePicker from '../pages/datePicker.vue'
import canvas from '../pages/canvas.vue'
Vue.use(Router)
export default new Router({
  routes: [
    {
      path: '/',
      name: index,
      component: index
    },
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/uploadFile',
      name: uploadFile,
      component: uploadFile
    },
    {
      path: '/datePicker',
      name: datePicker,
      component: datePicker
    },
    {
      path: '/canvas',
      name: canvas,
      component: canvas
    }
  ]
})
