import Vue from 'vue'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import util from './utils/jsencryptKey'
import dayjs from 'dayjs'
import router from './router'
Vue.use(ElementUI)
Vue.use(util)
Vue.prototype.$ajax = axios
Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
