<template>
    <div>
        <el-date-picker
            :picker-options="pickerOptions"
            v-model="selectTime"
            value-format="timestamp"
            type="datetime"
            placeholder="选择日期时间"
        >
        </el-date-picker>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectTime: "",
            pickerOptions: {
                //禁用日期
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7; // 选当前时间之后的时间
                    // return time.getTime() > Data.now(); //选当前时间之前的时间
                },
            },
        };
    },
    created() {
        this.getCurrentTime();
    },
    watch: {
        selectTime(newVal) {
            this.isToday(newVal);
        },
    },
    methods: {
        isToday(date) {
            if (new Date(date).toDateString() === new Date().toDateString()) {
                //是当天
                this.getCurrentTime();
            } else {
                this.$set(
                    this.pickerOptions,
                    "selectableRange",
                    "00:00:00 - 23:59:59"
                );
            }
            //else if (new Date(date) < new Date()){
            //之前时间
            //     console.log(new Date(date).toISOString().slice(0,10));
            // }
        },
        getCurrentTime() {
            let date = new Date().getTime();
            let currentTime = this.$dayjs(date).format("HH:mm:ss");
            this.$set(
                this.pickerOptions,
                "selectableRange",
                currentTime + "- 23:59:59"
            );
            //可以不需要---恢复当前时间
            if (this.selectTime && this.selectTime < date) {
                this.selectTime = date;
            }
        },
    },
};
</script>

<style>
</style>