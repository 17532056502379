import JSEncrypt from 'jsencrypt'

export default {
  install (Vue) {
    //加密
    Vue.prototype.getEncryCode = function (str) {
      //实例化JSEncrypt
      let jse = new JSEncrypt()
      //加密公钥（由服务端生成）
      let pubKey = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArZpa5IKS8b40QqSptgMS
  yh2AzYAsBPEPkxMtMTa02X6ggFJaCz0Qo1Xhlqn3dSuDmSnjvn3xMuCpU+c6G15E
  H1eht/UJp+4e/XnZRPBfntNyAmVHTBAdD4cfICOUsXa4crmWSXo0XJXnXLZuY1Tq
  NcGAJ/ZwDkmzV7vk1ZOkhy1hY7b9DUHOegq0rqb5oWz56OEy3kz12dzNGR63UdaI
  8lw2Siq3VI0MNuJyfxGZLK7Rh03FcJSn8URLAgLiS7mGnc/D+g6ENw2lP3ZhHKIO
  t/UkSYE9VpLYVBjtpceKHpxvty8B7WxU8y1C7QvrNrP/30xYFFMLiom+7MCfjwCA
  fQIDAQAB
  -----END PUBLIC KEY-----
  `

      jse.setPublicKey(pubKey)
      let data = jse.encrypt(str.toString()) // 进行加密
      return data
    }
    //解密
    //params 待解密字符串
    Vue.prototype.sendEncryCode = function (params) {
      //实例化JSEncrypt
      let jse = new JSEncrypt()
      //使用前端私钥解密（私钥由服务端生成）
      let privKey = `-----BEGIN PRIVATE KEY-----
  MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCtmlrkgpLxvjRC
  pKm2AxLKHYDNgCwE8Q+TEy0xNrTZfqCAUloLPRCjVeGWqfd1K4OZKeO+ffEy4KlT
  5zobXkQfV6G39Qmn7h79edlE8F+e03ICZUdMEB0Phx8gI5SxdrhyuZZJejRcledc
  tm5jVOo1wYAn9nAOSbNXu+TVk6SHLWFjtv0NQc56CrSupvmhbPno4TLeTPXZ3M0Z
  HrdR1ojyXDZKKrdUjQw24nJ/EZksrtGHTcVwlKfxREsCAuJLuYadz8P6DoQ3DaU/
  dmEcog639SRJgT1WkthUGO2lx4oenG+3LwHtbFTzLULtC+s2s//fTFgUUwuKib7s
  wJ+PAIB9AgMBAAECggEAaIo/28VJiauZOtx/mixlnzJ/AsEBQQSM9Wsh1rXz19kp
  +7wbnxLptwIbc6fsbTGD8LLKA0dB/PpO3UirtBli+mDB8M3tP6PkI43BqYL6geEg
  kZK9iW9WoKgktSzX+LnePq/FcX/Wkj5u8WSmOsjCtRZN/0KmneQBzH5IQdjDxeYv
  DZRYv0DhE8z5uMXSjLHyvZ4MjJ1ZbupahHtpRX7aaxu7+SAzYLSMWwvDOCc9O+Hl
  MBKMIMFoBndR2D+I+M9AcYhkJT3Tm8YR66i7/rI/OnN3MWycj87n3EkD3ycJ7LqH
  60yPC0Y7k3wem3sY+PuidP4HqUqvU2wIQtma18yR3QKBgQDTznyW0KI2pQmGz9qq
  WsVXPBZ6I9jh55wfYEfeDFtGFJA57UvOD7jr2/E3oM0FM2WzCil3fRuOohYy1cYM
  mhNYup4QBVa1gm7gBkE29C56NAIKmxQ1PvdNdjWqCEKAQGJ4KXEcJVpk92gxMd2Q
  uxFsxYAsT5vZvLQ8OvWU9l4uYwKBgQDR0z7VjV3VrCM+h6oVW90BdLIcGD8EEkxz
  AuzNL+svL5Fj7mm+e5jI/NngOrHbo4KmVAsdd5DjIfi50A1EEgcpQeBAM7sGnhZX
  xINaEqrZCyRlQLkbweHL1JYnrP2+YhYan0kOStxe6K5lvmnT3oJBNTUaYb6uyKRt
  YTjRntHbnwKBgGjiNlXkaH9QyF/mq6xyGIizK5o+CBcNvwTWg6AUVFpmsmjM9h3L
  MVIybseSLBQoOHMpVBFkdXHzF7UCoPj6jxwABWd/jUmClm7/CeoxjpZbDKu90Yrw
  bDwCjl5tiqjBuUExnF5IrPOUhBbgT4xs5hsLzgWjQlTXGhU9SQED4u1rAoGAY35r
  uQ+DgyJQxAHIYMBil6IiIrhMiT20CoVEjDdAqetAYcZXVf99fzCGj5DFbYSnGv+X
  Fa3wggNF0bYZ1EFILAQUtdzRvl2P8qY4MqnPYYOrCmgaXlMdIm33lXCuEWypVo5Z
  MfHFdSuYJp6Xx+MmmI0GVTAsHuz9KvCC5MMOd7kCgYEAxzvX178Smoq+9gMo4RfX
  WO4OeCtk9jCnydN4MtOZAfeGcVkh3pnop2Uxas0dRPf5zecpD0H3HwZXsoK9m9wR
  kufkk+SlW+fnezpaR+5oWErul/3zJ64iDqs/ySxPXm4AC11mMij9K/RFIqO79sHo
  nfGOuzahFWwS26WhtlRovLg=
  -----END PRIVATE KEY-----
  `

      jse.setPrivateKey(privKey)
      let data = jse.decrypt(params) //解密

      return data
    }
  }
}
