import axios from 'axios'
import { Message } from 'element-ui'
const service = axios.create({
  //   baseURL: 'http://sec.wxp.oa.com/cgi/aggr_risk/batchtask/',
  timeout: 6000,
  responseType: 'json',
  withCredentials: true
  //   headers: {
  //     'Content-Type': 'application/json;charset=utf-8'
  //   }
})

// 发包拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做什么
    return config
  },
  error => {
    // 对请求错误做些什么，自己定义
    // 使用element-ui的message进行信息提示
    Message.error(error)
    return Promise.reject(error)
  }
)
// 回包拦截器
service.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误

    return Promise.reject(response)
  },
  error => {
    Message.error('请求失败')
    console.log('error', error)
    return Promise.reject(error)
  }
)

export default service
