<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
// import $ from "jquery";
export default {
    data() {
        return {};
    },
    mounted() {
        // console.log("$", $);
    },
};
</script>

<style>
</style>